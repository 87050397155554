import { reactive } from 'vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IDTOClienteFinanceiro } from '@/models/DTO/Cadastros/Pessoas/Clientes/IDTOClienteFinanceiro';
import { IDTOBaixaTitulosPessoaTotal } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosPessoaTotal';
import { IDTOLancamentoBaixaConta } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOLancamentoBaixaConta';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IDTOTituloFinanceiroPendenteBaixa } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroPendenteBaixa';
import {
  IBaixaTitulos, IBaixaTitulosMovimentoFinanceiro, IBaixaTitulosTituloFinanceiroBaixado, IBaixaTitulosTituloFinanceiroLancado,
} from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoValorFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoValorFinanceiro';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { IDTOBaixaTitulosOrdenarVencimento } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosOrdenarVencimento';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import {
  ITituloFinanceiro, ITituloFinanceiroAnexo, ITituloFinanceiroBoleto, ITituloFinanceiroCentroCusto, ITituloFinanceiroCheque, ITituloFinanceiroDetalhamento, ITituloFinanceiroDocumentoFiscal, ITituloFinanceiroMarcador,
} from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import { ETipoEmissaoBoleto } from '@/models/Enumeradores/Financeiro/ETipoEmissaoBoletos';
import storeSistema from '@/store/storeSistema';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { IMovimentoFinanceiro } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import ServicoBaixaTitulosReceber from '@/servicos/Financeiro/ServicoBaixaTitulosReceber';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ESituacaoCheque } from '@/models/Enumeradores/Financeiro/ESituacaoCheque';
import { ESituacaoBoleto } from '@/models/Enumeradores/Financeiro/ESituacaoBoleto';
import { IDTOTituloFinanceiroDetalhamento } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroDetalhamento';
import { IDTOTituloFinanceiroObterDetalhamentoVariosTitulos } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroObterDetalhamentoVariosTitulos';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import { EMovimentoFinanceiroBaixaReferente } from '@/models/Enumeradores/Financeiro/EMovimentoFinanceiroBaixaReferente';
import { ETipoDetalhamentoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoDetalhamentoTituloFinanceiro';

export interface IAlertaBaixa {
    titulo: string;
    mensagem: string;
    tipo: string;
    visivel: boolean;
  }

export interface IBaixaTitulosBase {
    identificadorRecurso: string;
    identificadorPermissao: string;
    tipoTituloBaixa: ETipoTituloFinanceiro;
    codigoTituloPesquisa: number;
    contaPadrao: number;
    alertaBaixa: IAlertaBaixa;
    titulosBaixa: IDTOTituloFinanceiroPendenteBaixa[];
    creditosUtilizados: IDTOTituloFinanceiroPendenteBaixa[];
    totalCreditosUtilizados: number;
    totalSobraValores: number;
    totalCreditosLancados: number;
    creditosLancados: IDTOTituloFinanceiro[];
    utilizarCreditosApenasMesmaPessoa: boolean;
    lancamentosContaCreditos: IDTOLancamentoBaixaConta[];
    titulosLancados: IDTOTituloFinanceiro[];
    totalTitulosLancados: number;
    lancamentosContaTitulosLancados: IDTOLancamentoBaixaConta[];
    titulosRepassados: IDTOTituloFinanceiro[];
    totalTitulosRepassados: number;
    lancamentosContaNormais: IDTOLancamentoBaixaConta[];
    totalLancamentosContaNormais: number;
    dadosFinanceirosClientes: IDTOClienteFinanceiro[];
    colunasMovimentos: IColumn[];
    movimentosFinanceiros: IBaixaTitulosMovimentoFinanceiro[];
    empresaSelecionada: number;
    categoriaPadraoJurosMulta: number;
    categoriaPadraoDesconto: number;
    dataBaixa: string;
    calcularValoresTitulos: boolean;
    diasUteis: boolean;
    pessoaPadrao: number;
    pessoasTitulos: IDTOBaixaTitulosPessoaTotal[];
    tipoAcrescimo: number;
    tipoDesconto: number;
    valorAcrescimo: number;
    valorDesconto: number;
    percentualDesconto: number;
    percentualJuros: number;
    percentualMulta: number;
    totalTitulos: number;
    totalAcrescimos: number;
    totalDescontoAdicional: number;
    totalDesconto: number;
    totalJurosMulta: number;
    totalFinal: number;
    totalAcrescimosMovimentos: number;
    totalJurosMultaMovimentos: number;
    totalDescontosMovimentos: number;
    totalAbatimentosMovimentos: number;
    totalTaxasMovimentos: number;
    totalMovimentos: number;
    totalBaixa: number;
    valorRestante: number;
    totalMovimentoFinal: number;
    exibirBuscaTitulos: boolean;
    buscarCreditos: boolean;
    carregandoParametros: boolean;
    alteracaoPercentuais: boolean;
    percentualDescontoCliente: boolean;
    percentualJurosCliente: boolean;
    percentualMultaCliente: boolean;
    passoAtivo: string;
    detalhesPagamentos: IDTOTituloFinanceiroDetalhamento[];
    movimentosInvalidos: boolean;
    buscarDadosAtualizadosDetalhamentos: boolean;
    baixaComDetalhamentos: boolean;
}
export interface IRetornoBaixaTitulosBase{
    baixaTitulosBase: IBaixaTitulosBase;
    atualizarValorRestante(): void;
    limparTelaBase(): void;
    zerarValores(): void;
    calcularAcrescimosDescontosGerais(): void;
    calcularPercentuais(): void;
    verificacaoCalculo(): void;
    verificacaoAlteracaoData(): void;
    verificacaoAlertaBaixa(): void;
    calcularTotalizadoresMovimentos(): void;
    calcularValores(revisarTitulos: boolean): void;
    obterDadosFinanceirosClientes(): void;
    popularTitulosBaixa(titulos: IDTOTituloFinanceiro[]): void;
    obterDescricaoConta(codigoConta:number): string;
    obterDescricaoTipoMovimento(tipo: ETipoMovimentoFinanceiro): string;
    obterDescricaoInfluenciaSaldo(influenciaSaldo:boolean):string;
    obterTitulosLancados(): void;
    obterObjetoBaixa(): IBaixaTitulos;
    valorJurosMultaDigitado(index: number): void;
    valorAcrescimoDigitado(index: number): void;
    valorDescontoDigitado(index: number): void;
    prepararPreviaBaixa(): void;
}

export function useBaixaTitulosBase():IRetornoBaixaTitulosBase {
  const baixaTitulosBase = reactive({
    identificadorRecurso: '',
    identificadorPermissao: '',
    tipoTituloBaixa: 0,
    codigoTituloPesquisa: 0,
    contaPadrao: 0,
    alertaBaixa: {} as IAlertaBaixa,
    titulosBaixa: [] as IDTOTituloFinanceiroPendenteBaixa[],
    creditosUtilizados: [] as IDTOTituloFinanceiroPendenteBaixa[],
    totalCreditosUtilizados: 0,
    totalSobraValores: 0,
    totalCreditosLancados: 0,
    creditosLancados: [] as IDTOTituloFinanceiro[],
    utilizarCreditosApenasMesmaPessoa: true,
    lancamentosContaCreditos: [] as IDTOLancamentoBaixaConta[],
    titulosLancados: [] as IDTOTituloFinanceiro[],
    totalTitulosLancados: 0,
    lancamentosContaTitulosLancados: [] as IDTOLancamentoBaixaConta[],
    titulosRepassados: [] as IDTOTituloFinanceiro[],
    totalTitulosRepassados: 0,
    lancamentosContaTitulosRepassados: [] as IDTOLancamentoBaixaConta[],
    lancamentosContaNormais: [] as IDTOLancamentoBaixaConta[],
    totalLancamentosContaNormais: 0,
    dadosFinanceirosClientes: [] as IDTOClienteFinanceiro[],
    colunasMovimentos: [] as IColumn[],
    movimentosFinanceiros: [] as IBaixaTitulosMovimentoFinanceiro[],
    empresaSelecionada: 0,
    categoriaPadraoJurosMulta: 0,
    categoriaPadraoDesconto: 0,
    dataBaixa: '',
    calcularValoresTitulos: false,
    diasUteis: false,
    pessoaPadrao: 0,
    pessoasTitulos: [] as IDTOBaixaTitulosPessoaTotal[],
    tipoAcrescimo: 1,
    tipoDesconto: 1,
    valorAcrescimo: 0,
    valorDesconto: 0,
    percentualDesconto: 0,
    percentualJuros: 0,
    percentualMulta: 0,
    totalTitulos: 0,
    totalAcrescimos: 0,
    totalDescontoAdicional: 0,
    totalDesconto: 0,
    totalJurosMulta: 0,
    totalFinal: 0,
    totalAcrescimosMovimentos: 0,
    totalJurosMultaMovimentos: 0,
    totalDescontosMovimentos: 0,
    totalAbatimentosMovimentos: 0,
    totalTaxasMovimentos: 0,
    totalMovimentos: 0,
    totalBaixa: 0,
    valorRestante: 0,
    totalMovimentoFinal: 0,
    exibirBuscaTitulos: false,
    buscarCreditos: false,
    carregandoParametros: false,
    alteracaoPercentuais: false,
    percentualDescontoCliente: true,
    percentualJurosCliente: true,
    percentualMultaCliente: true,
    passoAtivo: '',
    detalhesPagamentos: [] as IDTOTituloFinanceiroDetalhamento[],
    movimentosInvalidos: false,
    buscarDadosAtualizadosDetalhamentos: false,
    baixaComDetalhamentos: false,
  });

  const { apresentarMensagemAlerta } = useTelaBase();
  const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
  servicoTituloFinanceiroReceber.requisicaoSistema();
  const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
  servicoTituloFinanceiroPagar.requisicaoSistema();

  function atualizarValorRestante() {
    let valorRestante = 0;
    let totalAcerto = 0;
    if (baixaTitulosBase.totalCreditosUtilizados > 0) {
      totalAcerto += UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalCreditosUtilizados);
    }

    if (baixaTitulosBase.totalTitulosLancados > 0) {
      totalAcerto += UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalTitulosLancados);
    }

    if (baixaTitulosBase.totalTitulosRepassados > 0) {
      totalAcerto += UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalTitulosRepassados);
    }

    if (baixaTitulosBase.totalLancamentosContaNormais > 0) {
      totalAcerto += UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalLancamentosContaNormais);
    }
    const totalBaixa = UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalBaixa);

    valorRestante = UtilitarioFinanceiro.valorDecimal2Casas(totalBaixa - totalAcerto);
    if (valorRestante < 0) { valorRestante = UtilitarioFinanceiro.valorDecimal2Casas(0); }
    baixaTitulosBase.valorRestante = valorRestante;
  }

  let numeroMovimentoControle = 0;
  let totalFinalCalculoAcrescimos = 0;
  let totalFinalCalculoDescontos = 0;
  let debounceJurosMulta = 0;
  let debounceDesconto = 0;
  let debounceAcrescimo = 0;
  let movimentosFinanceiros:IBaixaTitulosMovimentoFinanceiro[] = [];
  let titulosOriginais:IDTOTituloFinanceiroPendenteBaixa[] = [];
  let titulosParaBaixar:IDTOTituloFinanceiroPendenteBaixa[] = [];
  let creditosParaBaixar:IDTOTituloFinanceiroPendenteBaixa[] = [];
  let tipoMovimentoOperacao: ETipoMovimentoFinanceiro;

  function limparTelaBase() {
    baixaTitulosBase.diasUteis = false;
    baixaTitulosBase.passoAtivo = '';
    baixaTitulosBase.titulosBaixa = [] as IDTOTituloFinanceiroPendenteBaixa[];
    baixaTitulosBase.movimentosFinanceiros = [] as IBaixaTitulosMovimentoFinanceiro[];
    baixaTitulosBase.titulosLancados = [] as IDTOTituloFinanceiro[];
    baixaTitulosBase.titulosRepassados = [] as IDTOTituloFinanceiro[];
    baixaTitulosBase.totalTitulosRepassados = 0;
    baixaTitulosBase.lancamentosContaTitulosRepassados = [] as IDTOLancamentoBaixaConta[];
    baixaTitulosBase.dadosFinanceirosClientes = [] as IDTOClienteFinanceiro[];
    baixaTitulosBase.creditosUtilizados = [] as IDTOTituloFinanceiroPendenteBaixa[];
    baixaTitulosBase.totalCreditosUtilizados = 0;
    baixaTitulosBase.lancamentosContaCreditos = [] as IDTOLancamentoBaixaConta[];
    baixaTitulosBase.titulosLancados = [] as IDTOTituloFinanceiro[];
    baixaTitulosBase.totalTitulosLancados = 0;
    baixaTitulosBase.lancamentosContaTitulosLancados = [] as IDTOLancamentoBaixaConta[];
    baixaTitulosBase.lancamentosContaNormais = [] as IDTOLancamentoBaixaConta[];
    baixaTitulosBase.totalLancamentosContaNormais = 0;
    baixaTitulosBase.tipoAcrescimo = 1;
    baixaTitulosBase.tipoDesconto = 1;
    baixaTitulosBase.valorAcrescimo = 0;
    baixaTitulosBase.valorDesconto = 0;
    baixaTitulosBase.totalTitulos = 0;
    baixaTitulosBase.totalAcrescimos = 0;
    baixaTitulosBase.totalDescontoAdicional = 0;
    baixaTitulosBase.totalDesconto = 0;
    baixaTitulosBase.totalJurosMulta = 0;
    baixaTitulosBase.totalFinal = 0;
    baixaTitulosBase.totalAcrescimosMovimentos = 0;
    baixaTitulosBase.totalJurosMultaMovimentos = 0;
    baixaTitulosBase.totalCreditosUtilizados = 0;
    baixaTitulosBase.totalDescontosMovimentos = 0;
    baixaTitulosBase.totalAbatimentosMovimentos = 0;
    baixaTitulosBase.totalTaxasMovimentos = 0;
    baixaTitulosBase.totalMovimentos = 0;
    baixaTitulosBase.totalBaixa = 0;
    baixaTitulosBase.valorRestante = 0;
    baixaTitulosBase.movimentosInvalidos = false;
    baixaTitulosBase.buscarDadosAtualizadosDetalhamentos = false;
    const recebimentoNormal = { codigoConta: baixaTitulosBase.contaPadrao, dataBaixa: baixaTitulosBase.dataBaixa, valor: 0 } as IDTOLancamentoBaixaConta;
    baixaTitulosBase.lancamentosContaNormais.push(recebimentoNormal);
  }

  function zerarValores() {
    baixaTitulosBase.totalTitulos = 0;
    baixaTitulosBase.totalJurosMulta = 0;
    baixaTitulosBase.totalAcrescimos = 0;
    baixaTitulosBase.totalDesconto = 0;
    baixaTitulosBase.totalBaixa = 0;
    totalFinalCalculoAcrescimos = 0;
    totalFinalCalculoDescontos = 0;
  }

  function calcularAcrescimosDescontosGerais() {
    let totalAcrescimosDigitadoManual = 0;

    if (baixaTitulosBase.tipoAcrescimo === ETipoValorFinanceiro.ValorNominal) {
      baixaTitulosBase.totalAcrescimos = baixaTitulosBase.valorAcrescimo;
    } else if (baixaTitulosBase.tipoAcrescimo === ETipoValorFinanceiro.Percentual && totalFinalCalculoAcrescimos > 0) {
      const valorNominal = UtilitarioFinanceiro.calcularAcrescimo(totalFinalCalculoAcrescimos, baixaTitulosBase.valorAcrescimo);
      baixaTitulosBase.totalAcrescimos = valorNominal;
    } else {
      baixaTitulosBase.totalAcrescimos = 0;
    }
    if (baixaTitulosBase.tipoDesconto === ETipoValorFinanceiro.ValorNominal) {
      baixaTitulosBase.totalDescontoAdicional = baixaTitulosBase.valorDesconto;
    } else if (baixaTitulosBase.tipoDesconto === ETipoValorFinanceiro.Percentual && totalFinalCalculoDescontos > 0) {
      const valorNominal = UtilitarioFinanceiro.calcularDesconto(totalFinalCalculoDescontos, baixaTitulosBase.valorDesconto);
      baixaTitulosBase.totalDescontoAdicional = valorNominal;
    } else {
      baixaTitulosBase.totalDescontoAdicional = 0;
    }

    if (baixaTitulosBase.totalAcrescimos > 0 || totalFinalCalculoAcrescimos > 0 || baixaTitulosBase.totalDescontoAdicional > 0 || totalFinalCalculoDescontos > 0) {
      baixaTitulosBase.titulosBaixa.forEach((t, index) => {
        if ((baixaTitulosBase.totalAcrescimos > 0 && totalFinalCalculoAcrescimos > 0) && !baixaTitulosBase.titulosBaixa[index].definidoValorAcrescimoManual) {
          const proporcao = (baixaTitulosBase.titulosBaixa[index].valorTotal / totalFinalCalculoAcrescimos) * 100;
          baixaTitulosBase.titulosBaixa[index].valorAcrescimo = UtilitarioFinanceiro.calcularAcrescimo(baixaTitulosBase.totalAcrescimos, proporcao);
        } else {
          totalAcrescimosDigitadoManual += baixaTitulosBase.titulosBaixa[index].valorAcrescimo;
        }

        if ((baixaTitulosBase.totalDescontoAdicional > 0 && totalFinalCalculoDescontos > 0) && !baixaTitulosBase.titulosBaixa[index].definidoValorDescontoManual) {
          const proporcao = (baixaTitulosBase.titulosBaixa[index].valorTotal / totalFinalCalculoDescontos) * 100;
          const valorDesconto = UtilitarioFinanceiro.calcularDesconto(baixaTitulosBase.totalDescontoAdicional, proporcao);
          baixaTitulosBase.titulosBaixa[index].valorDesconto += valorDesconto;
          baixaTitulosBase.totalDesconto += valorDesconto;
        }

        baixaTitulosBase.titulosBaixa[index].valorTotal = (baixaTitulosBase.titulosBaixa[index].saldo + baixaTitulosBase.titulosBaixa[index].valorJurosMulta + baixaTitulosBase.titulosBaixa[index].valorAcrescimo - baixaTitulosBase.titulosBaixa[index].valorDesconto);
      });
    }

    baixaTitulosBase.totalAcrescimos += totalAcrescimosDigitadoManual;
  }

  function calcularPercentuais() {
    baixaTitulosBase.pessoasTitulos = [];
    baixaTitulosBase.titulosBaixa.forEach((t, index) => {
      const diasAtraso = UtilitarioFinanceiro.obterDiasAtraso(baixaTitulosBase.dataBaixa, t.dataVencimento, baixaTitulosBase.diasUteis);

      baixaTitulosBase.titulosBaixa[index].diasAtraso = diasAtraso;
      let financeiroCliente = {} as IDTOClienteFinanceiro;
      if (baixaTitulosBase.percentualDescontoCliente || baixaTitulosBase.percentualJurosCliente || baixaTitulosBase.percentualMultaCliente) {
        const dadoFinanceiro = baixaTitulosBase.dadosFinanceirosClientes.find((f) => f.codigoPessoa === t.codigoPessoa);
        if (dadoFinanceiro !== undefined) {
          financeiroCliente = dadoFinanceiro;
        }
      }

      if (!baixaTitulosBase.titulosBaixa[index].definidoValorDescontoManual) {
        baixaTitulosBase.titulosBaixa[index].valorDesconto = 0;
        if (diasAtraso === 0) {
          if (baixaTitulosBase.percentualDescontoCliente && financeiroCliente.desconto > 0) {
            baixaTitulosBase.titulosBaixa[index].valorDesconto = UtilitarioFinanceiro.calcularDesconto(t.saldo, financeiroCliente.desconto);
          } else if (baixaTitulosBase.percentualDesconto > 0) {
            baixaTitulosBase.titulosBaixa[index].valorDesconto = UtilitarioFinanceiro.calcularDesconto(t.saldo, baixaTitulosBase.percentualDesconto);
          }
        }
      }
      baixaTitulosBase.totalDesconto += baixaTitulosBase.titulosBaixa[index].valorDesconto;

      if (!baixaTitulosBase.titulosBaixa[index].definidoValorJurosMultaManual) {
        baixaTitulosBase.titulosBaixa[index].valorJurosMulta = 0;
        if (diasAtraso > 0) {
          if (baixaTitulosBase.percentualJurosCliente && financeiroCliente.juros > 0) {
            baixaTitulosBase.titulosBaixa[index].valorJurosMulta = UtilitarioFinanceiro.calcularJuros(t.saldo, financeiroCliente.juros, diasAtraso);
          } else if (baixaTitulosBase.percentualJuros > 0) {
            baixaTitulosBase.titulosBaixa[index].valorJurosMulta = UtilitarioFinanceiro.calcularJuros(t.saldo, baixaTitulosBase.percentualJuros, diasAtraso);
          }

          if (baixaTitulosBase.percentualMultaCliente && financeiroCliente.multa > 0) {
            baixaTitulosBase.titulosBaixa[index].valorJurosMulta += UtilitarioFinanceiro.calcularMulta(t.saldo, financeiroCliente.multa);
          } else if (baixaTitulosBase.percentualMulta > 0) {
            baixaTitulosBase.titulosBaixa[index].valorJurosMulta += UtilitarioFinanceiro.calcularMulta(t.saldo, baixaTitulosBase.percentualMulta);
          }
        }
      }
      baixaTitulosBase.totalJurosMulta += baixaTitulosBase.titulosBaixa[index].valorJurosMulta;

      baixaTitulosBase.titulosBaixa[index].valorTotal = (baixaTitulosBase.titulosBaixa[index].saldo + baixaTitulosBase.titulosBaixa[index].valorJurosMulta - baixaTitulosBase.titulosBaixa[index].valorDesconto);
      baixaTitulosBase.totalTitulos += baixaTitulosBase.titulosBaixa[index].saldo;

      if (!baixaTitulosBase.titulosBaixa[index].definidoValorAcrescimoManual) {
        baixaTitulosBase.titulosBaixa[index].valorAcrescimo = 0;
        totalFinalCalculoAcrescimos += baixaTitulosBase.titulosBaixa[index].valorTotal;
      }

      if (!baixaTitulosBase.titulosBaixa[index].definidoValorDescontoManual) {
        totalFinalCalculoDescontos += baixaTitulosBase.titulosBaixa[index].valorTotal;
      }

      const indexPessoa = baixaTitulosBase.pessoasTitulos.findIndex((c) => c.codigoPessoa === t.codigoPessoa);
      if (indexPessoa >= 0) {
        baixaTitulosBase.pessoasTitulos[indexPessoa].totalTitulos += t.valorTotal;
      } else {
        const pessoasTitulo:IDTOBaixaTitulosPessoaTotal = { codigoPessoa: t.codigoPessoa, totalTitulos: t.valorTotal } as IDTOBaixaTitulosPessoaTotal;
        baixaTitulosBase.pessoasTitulos.push(pessoasTitulo);
      }
    });
  }

  function verificacaoCalculo() {
    if (baixaTitulosBase.titulosBaixa.length > 0) {
      baixaTitulosBase.calcularValoresTitulos = true;
    }
  }

  function verificacaoAlteracaoData() {
    verificacaoCalculo();
    for (let index = 0; index < baixaTitulosBase.lancamentosContaNormais.length; index += 1) {
      baixaTitulosBase.lancamentosContaNormais[index].dataBaixa = baixaTitulosBase.dataBaixa;
    }
    baixaTitulosBase.passoAtivo = '';
  }

  function verificacaoAlertaBaixa() {
    if (!baixaTitulosBase.movimentosInvalidos) return;

    const totalAcerto = UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalCreditosUtilizados + baixaTitulosBase.totalTitulosLancados + baixaTitulosBase.totalTitulosRepassados + baixaTitulosBase.totalLancamentosContaNormais);
    const totalBaixa = UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalBaixa);
    baixaTitulosBase.totalSobraValores = 0;
    baixaTitulosBase.alertaBaixa.visivel = false;
    if (totalAcerto > 0 && totalAcerto === totalBaixa) {
      baixaTitulosBase.alertaBaixa.tipo = 'success';
      baixaTitulosBase.alertaBaixa.titulo = 'Você está realizando uma Baixa Total';
      baixaTitulosBase.alertaBaixa.mensagem = baixaTitulosBase.titulosBaixa.length > 1 ? 'Todos os títulos selecionados serão baixados.' : 'O título selecionado será baixado.';
      baixaTitulosBase.alertaBaixa.visivel = true;
    } else if (totalAcerto > 0 && totalAcerto < totalBaixa) {
      baixaTitulosBase.alertaBaixa.tipo = 'warning';
      baixaTitulosBase.alertaBaixa.titulo = 'Você está realizando uma Baixa Parcial';
      baixaTitulosBase.alertaBaixa.mensagem = `Restam R$ ${UtilitarioMascara.mascararValor((totalBaixa - totalAcerto), 2)} para realizar a baixa total.`;
      baixaTitulosBase.alertaBaixa.visivel = true;
    } else if (totalAcerto > 0 && totalAcerto > totalBaixa) {
      baixaTitulosBase.totalSobraValores = UtilitarioFinanceiro.valorDecimal2Casas(totalAcerto - totalBaixa);
      baixaTitulosBase.alertaBaixa.tipo = 'success';
      baixaTitulosBase.alertaBaixa.titulo = 'Você está realizando uma Baixa Total com Sobra de Valores';
      baixaTitulosBase.alertaBaixa.mensagem = baixaTitulosBase.titulosBaixa.length > 1 ? 'Todos os títulos selecionados serão baixados.' : 'O título selecionado será baixado.';
      baixaTitulosBase.alertaBaixa.mensagem = `Efetue o lançamento do crédito abaixo totalizando R$ ${UtilitarioMascara.mascararValor(baixaTitulosBase.totalSobraValores)}.`;
      baixaTitulosBase.alertaBaixa.visivel = true;
    }
  }

  function calcularTotalizadoresMovimentos() {
    baixaTitulosBase.totalAcrescimosMovimentos = 0;
    baixaTitulosBase.totalJurosMultaMovimentos = 0;
    baixaTitulosBase.totalDescontosMovimentos = 0;
    baixaTitulosBase.totalAbatimentosMovimentos = 0;
    baixaTitulosBase.totalTaxasMovimentos = 0;
    baixaTitulosBase.totalMovimentos = 0;
    baixaTitulosBase.totalMovimentoFinal = 0;

    baixaTitulosBase.movimentosFinanceiros.forEach((m) => {
      switch (m.referente) {
        case EMovimentoFinanceiroBaixaReferente.BaixaTitulo:
          baixaTitulosBase.totalMovimentos += UtilitarioFinanceiro.valorDecimal2Casas(m.movimentoFinanceiro.valor);
          break;
        case EMovimentoFinanceiroBaixaReferente.Acrescimo:
          baixaTitulosBase.totalAcrescimosMovimentos += UtilitarioFinanceiro.valorDecimal2Casas(m.movimentoFinanceiro.valor);
          break;
        case EMovimentoFinanceiroBaixaReferente.JurosMulta:
          baixaTitulosBase.totalJurosMultaMovimentos += UtilitarioFinanceiro.valorDecimal2Casas(m.movimentoFinanceiro.valor);
          break;
        case EMovimentoFinanceiroBaixaReferente.Desconto:
          baixaTitulosBase.totalDescontosMovimentos += UtilitarioFinanceiro.valorDecimal2Casas(m.movimentoFinanceiro.valor);
          break;
        case EMovimentoFinanceiroBaixaReferente.Abatimento:
          baixaTitulosBase.totalAbatimentosMovimentos += UtilitarioFinanceiro.valorDecimal2Casas(m.movimentoFinanceiro.valor);
          break;
        case EMovimentoFinanceiroBaixaReferente.Taxa:
          baixaTitulosBase.totalTaxasMovimentos += UtilitarioFinanceiro.valorDecimal2Casas(m.movimentoFinanceiro.valor);
          break;
        default:
          break;
      }
    });
    baixaTitulosBase.totalMovimentoFinal = ((baixaTitulosBase.totalMovimentos + baixaTitulosBase.totalJurosMultaMovimentos + baixaTitulosBase.totalAcrescimosMovimentos) - (baixaTitulosBase.totalDescontosMovimentos + baixaTitulosBase.totalAbatimentosMovimentos + baixaTitulosBase.totalTaxasMovimentos));
    verificacaoAlertaBaixa();
  }

  function calcularValores(revisarTitulos: boolean) {
    zerarValores();
    calcularPercentuais();
    baixaTitulosBase.totalFinal = baixaTitulosBase.totalTitulos + baixaTitulosBase.totalJurosMulta - baixaTitulosBase.totalDesconto;
    calcularAcrescimosDescontosGerais();
    baixaTitulosBase.totalFinal = UtilitarioFinanceiro.valorDecimal2Casas(baixaTitulosBase.totalTitulos + baixaTitulosBase.totalAcrescimos + baixaTitulosBase.totalJurosMulta - baixaTitulosBase.totalDesconto);
    baixaTitulosBase.totalBaixa = baixaTitulosBase.totalFinal;
    baixaTitulosBase.calcularValoresTitulos = false;
    if (revisarTitulos) { baixaTitulosBase.passoAtivo = '1'; }
  }

  async function obterDadosFinanceirosClientes() {
    const pessoas = [] as number[];

    baixaTitulosBase.titulosBaixa.forEach((t) => {
      if (!pessoas.some((p) => p === t.codigoPessoa)) {
        pessoas.push(t.codigoPessoa);
      }
    });
    baixaTitulosBase.dadosFinanceirosClientes = await new ServicoBaixaTitulosReceber().obterDadosFinanceirosClientes(pessoas, baixaTitulosBase.empresaSelecionada);
  }

  async function popularTitulosBaixa(titulos: IDTOTituloFinanceiro[]) {
    let titulosAdicionados = false;
    const titulosBaixa:IDTOTituloFinanceiroPendenteBaixa[] = UtilitarioGeral.instanciaObjetoLocal(baixaTitulosBase.titulosBaixa);
    titulos.forEach((t) => {
      const index = titulosBaixa.findIndex((ts) => ts.codigoTituloFinanceiro === t.codigo);
      if (index === -1) {
        if (t.status === EStatusTituloFinanceiro.Pendente || t.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
          const tituloBaixa = {} as IDTOTituloFinanceiroPendenteBaixa;
          tituloBaixa.codigoBaixaTitulos = 0;
          tituloBaixa.codigoMovimentoFinanceiro = 0;
          tituloBaixa.codigoEmpresa = t.codigoEmpresa;
          tituloBaixa.codigoTituloFinanceiro = t.codigo;
          tituloBaixa.codigoConta = t.codigoConta;
          tituloBaixa.codigoPlanoContaCategoria = t.codigoPlanoContaCategoria;
          tituloBaixa.codigoPessoa = t.codigoPessoa;
          tituloBaixa.codigoTipoDocumentoFinanceiro = t.codigoTipoDocumentoFinanceiro;
          tituloBaixa.dataCriacao = t.dataCriacao;
          tituloBaixa.dataVencimento = t.dataVencimento;
          tituloBaixa.descricaoConta = t.descricaoConta;
          tituloBaixa.descricaoTipo = t.descricaoTipo;
          tituloBaixa.descricaoTipoDocumentoFinanceiro = t.descricaoTipoDocumentoFinanceiro;
          tituloBaixa.siglaTipoDocumentoFinanceiro = t.siglaTipoDocumentoFinanceiro;
          tituloBaixa.nomePessoa = t.nomePessoa;
          tituloBaixa.nomePlanoContaCategoria = t.nomePlanoContaCategoria;
          tituloBaixa.numeroTitulo = t.numeroTitulo;
          tituloBaixa.observacoes = t.observacoes;
          tituloBaixa.saldo = t.saldo;
          tituloBaixa.status = t.status;
          tituloBaixa.tipo = t.tipo;
          tituloBaixa.valorAcrescimo = 0;
          tituloBaixa.valorDesconto = 0;
          tituloBaixa.valorJurosMulta = 0;
          tituloBaixa.valorPago = 0;
          tituloBaixa.credito = false;
          tituloBaixa.valorUtilizado = 0;
          tituloBaixa.motivoRepasse = '';
          tituloBaixa.diasAtraso = 0;
          tituloBaixa.definidoValorAcrescimoManual = false;
          tituloBaixa.definidoValorDescontoManual = false;
          tituloBaixa.definidoValorJurosMultaManual = false;
          titulosAdicionados = true;
          titulosBaixa.push(tituloBaixa);
        }
      }
    });
    if (titulosAdicionados) {
      if (titulosBaixa.length > 1) {
        const baixaTitulosOrdenar:IDTOBaixaTitulosOrdenarVencimento = {} as IDTOBaixaTitulosOrdenarVencimento;
        baixaTitulosOrdenar.titulosPendentes = [];
        baixaTitulosOrdenar.titulosPendentes = titulosBaixa;
        const titulosOrdenadosVencimento = await new ServicoBaixaTitulosReceber().ordernarTitulosVencimento(baixaTitulosOrdenar);
        if (UtilitarioGeral.validaLista(titulosOrdenadosVencimento)) {
          baixaTitulosBase.titulosBaixa = titulosOrdenadosVencimento;
        }
      } else {
        baixaTitulosBase.titulosBaixa = titulosBaixa;
      }

      const dados:IDTOTituloFinanceiroObterDetalhamentoVariosTitulos = {} as IDTOTituloFinanceiroObterDetalhamentoVariosTitulos;
      dados.empresas = baixaTitulosBase.titulosBaixa.map((c) => c.codigoEmpresa);
      dados.tipoTituloFinanceiro = baixaTitulosBase.tipoTituloBaixa;
      dados.codigosTituloFinanceiros = baixaTitulosBase.titulosBaixa.map((c) => c.codigoTituloFinanceiro);

      if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Receber) {
        await obterDadosFinanceirosClientes();
        baixaTitulosBase.detalhesPagamentos = await servicoTituloFinanceiroReceber.obterDetalhamentosVariosTitulos(dados);
      } else if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Pagar) {
        baixaTitulosBase.detalhesPagamentos = await servicoTituloFinanceiroPagar.obterDetalhamentosVariosTitulos(dados);
      }

      baixaTitulosBase.baixaComDetalhamentos = UtilitarioGeral.validaLista(baixaTitulosBase.detalhesPagamentos);
      calcularValores(false);
    }
  }

  function obterDescricaoConta(codigoConta:number) {
    if (UtilitarioGeral.validaLista(baixaTitulosBase.lancamentosContaNormais)) {
      const conta = baixaTitulosBase.lancamentosContaNormais.find((c) => c.codigoConta === codigoConta);
      if (conta !== undefined) {
        return conta.descricaoConta;
      }
    }

    if (UtilitarioGeral.validaLista(baixaTitulosBase.lancamentosContaCreditos)) {
      const conta = baixaTitulosBase.lancamentosContaCreditos.find((c) => c.codigoConta === codigoConta);
      if (conta !== undefined) {
        return conta.descricaoConta;
      }
    }

    if (UtilitarioGeral.validaLista(baixaTitulosBase.lancamentosContaTitulosLancados)) {
      const conta = baixaTitulosBase.lancamentosContaTitulosLancados.find((c) => c.codigoConta === codigoConta);
      if (conta !== undefined) {
        return conta.descricaoConta;
      }
    }

    if (UtilitarioGeral.validaLista(baixaTitulosBase.lancamentosContaTitulosRepassados)) {
      const conta = baixaTitulosBase.lancamentosContaTitulosRepassados.find((c) => c.codigoConta === codigoConta);
      if (conta !== undefined) {
        return conta.descricaoConta;
      }
    }

    return '';
  }

  function obterDescricaoTipoMovimento(tipo: ETipoMovimentoFinanceiro) {
    switch (tipo) {
      case ETipoMovimentoFinanceiro.Recebimento:
        return 'Recebimento';
      case ETipoMovimentoFinanceiro.Pagamento:
        return 'Pagamento';
      default:
        return '';
    }
  }

  function obterDescricaoInfluenciaSaldo(influenciaSaldo:boolean) {
    return influenciaSaldo ? 'Sim' : 'Não';
  }

  function criarTituloLancado(t: IDTOTituloFinanceiro):IBaixaTitulosTituloFinanceiroLancado {
    const tituloLancado = {} as IBaixaTitulosTituloFinanceiroLancado;
    tituloLancado.titulo = {} as ITituloFinanceiro;
    tituloLancado.titulo.codigoConta = t.codigoConta;
    tituloLancado.titulo.codigoPlanoContaCategoria = t.codigoPlanoContaCategoria;
    tituloLancado.titulo.codigoEmpresa = t.codigoEmpresa;
    tituloLancado.titulo.codigoPessoa = t.codigoPessoa;
    tituloLancado.titulo.codigoTipoDocumentoFinanceiro = t.codigoTipoDocumentoFinanceiro;
    tituloLancado.titulo.dataCriacao = t.dataCriacao;
    tituloLancado.titulo.dataCompetencia = t.dataCompetencia;
    tituloLancado.titulo.dataVencimento = t.dataVencimento;
    tituloLancado.titulo.numeroTitulo = t.numeroTitulo;
    tituloLancado.titulo.observacoes = t.observacoes;
    tituloLancado.titulo.parcela = t.parcela;
    tituloLancado.titulo.status = t.status;
    tituloLancado.titulo.tipo = t.tipo;
    tituloLancado.titulo.valorTotal = t.valorTotal;
    tituloLancado.titulo.saldo = t.valorTotal;
    tituloLancado.titulo.documentoFiscal = {} as ITituloFinanceiroDocumentoFiscal;
    tituloLancado.titulo.documentoFiscal.tipoDocumento = 1;
    tituloLancado.titulo.documentoFiscal.origem = 1;
    tituloLancado.titulo.boleto = {} as ITituloFinanceiroBoleto;
    tituloLancado.titulo.boleto.codigo = 0;
    tituloLancado.titulo.boleto.codigoTituloFinanceiro = 0;
    tituloLancado.titulo.boleto.tipoEmissaoBoleto = ETipoEmissaoBoleto.Terceiros;
    tituloLancado.titulo.boleto.situacao = ESituacaoBoleto.NaoEmitido;
    tituloLancado.titulo.boleto.numeroDocumento = t.numeroTitulo;
    tituloLancado.titulo.boleto.chaveBoleto = '';
    tituloLancado.titulo.cheque = {} as ITituloFinanceiroCheque;
    tituloLancado.titulo.cheque.codigo = 0;
    tituloLancado.titulo.cheque.codigoTituloFinanceiro = 0;
    tituloLancado.titulo.cheque.situacao = ESituacaoCheque.PreDatado;
    tituloLancado.titulo.cheque.terceiros = false;
    tituloLancado.titulo.centrosCusto = [] as ITituloFinanceiroCentroCusto[];
    tituloLancado.titulo.detalhamentos = [] as ITituloFinanceiroDetalhamento[];
    tituloLancado.titulo.anexos = [] as ITituloFinanceiroAnexo[];
    tituloLancado.titulo.marcadores = [] as ITituloFinanceiroMarcador[];

    return tituloLancado;
  }

  function obterTitulosLancados() {
    const titulos = [] as IBaixaTitulosTituloFinanceiroLancado[];

    if (UtilitarioGeral.validaLista(baixaTitulosBase.titulosLancados)) {
      baixaTitulosBase.titulosLancados.forEach((t) => {
        titulos.push(criarTituloLancado(t));
      });
    }

    if (UtilitarioGeral.validaLista(baixaTitulosBase.creditosLancados)) {
      baixaTitulosBase.creditosLancados.forEach((t) => {
        titulos.push(criarTituloLancado(t));
      });
    }

    return titulos;
  }

  function obterObjetoBaixa(): IBaixaTitulos {
    const baixa = {} as IBaixaTitulos;
    baixa.codigoEmpresa = baixaTitulosBase.empresaSelecionada;
    baixa.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
    baixa.tipoTituloFinanceiro = baixaTitulosBase.tipoTituloBaixa;
    baixa.dataBaixa = baixaTitulosBase.dataBaixa;
    baixa.tipoAcrescimo = baixaTitulosBase.tipoAcrescimo;
    baixa.tipoDesconto = baixaTitulosBase.tipoDesconto;
    baixa.acrescimo = baixaTitulosBase.valorAcrescimo;
    baixa.descontoAdicional = baixaTitulosBase.totalDescontoAdicional;
    baixa.percentualJuros = baixaTitulosBase.percentualJuros;
    baixa.percentualMulta = baixaTitulosBase.percentualMulta;
    baixa.percentualDesconto = baixaTitulosBase.percentualDesconto;
    baixa.qtdTitulos = baixaTitulosBase.titulosBaixa.length;
    baixa.totalTitulos = baixaTitulosBase.totalTitulos;
    baixa.totalJurosMulta = baixaTitulosBase.totalJurosMultaMovimentos;
    baixa.totalAcrescimos = baixaTitulosBase.totalAcrescimosMovimentos;
    baixa.totalDescontos = baixaTitulosBase.totalDescontosMovimentos;
    baixa.totalAbatimentos = baixaTitulosBase.totalAbatimentosMovimentos;
    baixa.totalTaxas = baixaTitulosBase.totalTaxasMovimentos;
    baixa.totalSobraValores = baixaTitulosBase.totalSobraValores;
    baixa.totalTitulosRepassados = baixaTitulosBase.totalTitulosRepassados;
    baixa.totalBaixa = baixaTitulosBase.totalMovimentoFinal;
    baixa.titulosRepassados = baixaTitulosBase.titulosRepassados;
    baixa.movimentosFinanceiros = baixaTitulosBase.movimentosFinanceiros;
    baixa.titulosLancados = obterTitulosLancados();
    return baixa;
  }

  function valorJurosMultaDigitado(index: number) {
    clearTimeout(debounceJurosMulta);
    debounceJurosMulta = setTimeout(async () => {
      baixaTitulosBase.titulosBaixa[index].definidoValorJurosMultaManual = true;
      calcularValores(false);
    }, 800);
  }

  function valorAcrescimoDigitado(index: number) {
    clearTimeout(debounceAcrescimo);
    debounceAcrescimo = setTimeout(async () => {
      baixaTitulosBase.titulosBaixa[index].definidoValorAcrescimoManual = true;
      calcularValores(false);
    }, 800);
  }
  function valorDescontoDigitado(index: number) {
    clearTimeout(debounceDesconto);
    debounceDesconto = setTimeout(async () => {
      baixaTitulosBase.titulosBaixa[index].definidoValorDescontoManual = true;
      calcularValores(false);
    }, 800);
  }

  function obterTituloBaixa(titulo:IDTOTituloFinanceiroPendenteBaixa, renegociado: boolean, totalAbatimentos = 0):IBaixaTitulosTituloFinanceiroBaixado {
    const tituloBaixa = {} as IBaixaTitulosTituloFinanceiroBaixado;
    tituloBaixa.codigo = 0;
    tituloBaixa.codigoBaixaTitulosMovimentoFinanceiro = 0;
    tituloBaixa.codigoTituloFinanceiro = titulo.codigoTituloFinanceiro;
    tituloBaixa.valorJurosMulta = titulo.valorJurosMulta;
    tituloBaixa.valorDesconto = titulo.valorDesconto;
    tituloBaixa.valorAcrescimo = titulo.valorAcrescimo;
    tituloBaixa.valorPago = titulo.valorPago;
    tituloBaixa.valorTaxa = 0;
    tituloBaixa.valorAbatimento = totalAbatimentos;
    tituloBaixa.renegociado = renegociado;
    return tituloBaixa;
  }

  function criarMovimentoFinanceiro(codigoConta: number,
    tipoMovimento:ETipoMovimentoFinanceiro,
    referente: EMovimentoFinanceiroBaixaReferente,
    descricaoMovimento: string,
    complementoIdentificador:string,
    influenciaSaldo: boolean,
    valor:number,
    codigoPlanoContaCategoria: number,
    adicionarTituloBaixado: boolean,
    tituloFinanceiro:IDTOTituloFinanceiroPendenteBaixa,
    renegociado: boolean): IBaixaTitulosMovimentoFinanceiro[] {
    const movimentosFinanceiros: IBaixaTitulosMovimentoFinanceiro[] = [];

    let detalhamentosTitulo:IDTOTituloFinanceiroDetalhamento[] = [];
    if (UtilitarioGeral.validaLista(baixaTitulosBase.detalhesPagamentos)) {
      detalhamentosTitulo = baixaTitulosBase.detalhesPagamentos.filter((c) => c.codigoTituloFinanceiro === tituloFinanceiro.codigoTituloFinanceiro);
    }
    if (referente === EMovimentoFinanceiroBaixaReferente.BaixaTitulo && UtilitarioGeral.validaLista(detalhamentosTitulo)) {
      detalhamentosTitulo = detalhamentosTitulo.sort((a, b) => a.ordem - b.ordem);
      const detalhamentosInformativos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo);
      if (!UtilitarioGeral.validaLista(detalhamentosInformativos)) {
        apresentarMensagemAlerta(`O título: ${tituloFinanceiro.numeroTitulo} não possuí detalhamentos informativos.`);
        baixaTitulosBase.movimentosInvalidos = true;
        return [];
      }
      const totalInformativosTitulo = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosInformativos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));
      let totalAbatimentosTitulo = 0;
      const detalhamentosAbatimentos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento);
      if (UtilitarioGeral.validaLista(detalhamentosAbatimentos)) {
        totalAbatimentosTitulo = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosAbatimentos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));
      }

      const totalLiquidoDetalhamentos = UtilitarioFinanceiro.valorDecimal2Casas(totalInformativosTitulo - totalAbatimentosTitulo);
      const saldoTitulo = UtilitarioFinanceiro.valorDecimal2Casas(tituloFinanceiro.saldo);
      if (totalLiquidoDetalhamentos !== saldoTitulo) {
        apresentarMensagemAlerta(`O total dos detalhamentos não bate com o saldo do título: ${tituloFinanceiro.numeroTitulo}.`);
        baixaTitulosBase.movimentosInvalidos = true;
        baixaTitulosBase.buscarDadosAtualizadosDetalhamentos = true;
        return [];
      }

      const maiorDetalhamentoInformativo = detalhamentosInformativos.reduce((max, detalhamento) => (detalhamento.valor > max.valor ? detalhamento : max));
      const indexMaiorDetalhamentoInformativo = detalhamentosTitulo.findIndex((detalhamento) => detalhamento === maiorDetalhamentoInformativo);
      for (let indexDetalhamento = 0; indexDetalhamento < detalhamentosTitulo.length; indexDetalhamento += 1) {
        numeroMovimentoControle += 1;
        const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
        movimento.referente = referente;
        movimento.codigoTituloFinanceiroOrigem = tituloFinanceiro.codigoTituloFinanceiro;
        movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
        movimento.movimentoFinanceiro.codigoConta = codigoConta;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = detalhamentosTitulo[indexDetalhamento].codigoPlanoContaCategoria;
        movimento.movimentoFinanceiro.codigoEmpresa = tituloFinanceiro.codigoEmpresa;
        movimento.movimentoFinanceiro.codigoPessoa = tituloFinanceiro.codigoPessoa;
        movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = tituloFinanceiro.codigoTipoDocumentoFinanceiro;
        movimento.movimentoFinanceiro.conciliado = false;
        movimento.movimentoFinanceiro.influenciaSaldo = influenciaSaldo;
        movimento.movimentoFinanceiro.dataMovimento = baixaTitulosBase.dataBaixa;
        movimento.movimentoFinanceiro.descricao = `${descricaoMovimento} - ${detalhamentosTitulo[indexDetalhamento].descricao}`;
        movimento.movimentoFinanceiro.identificadorMovimento = `${tituloFinanceiro.numeroTitulo}-${UtilitarioGeral.gerarIdentificadorTexto(detalhamentosTitulo[indexDetalhamento].descricao).toUpperCase()}-M${numeroMovimentoControle}`;
        movimento.movimentoFinanceiro.marcadores = [];
        movimento.movimentoFinanceiro.recursoOrigem = baixaTitulosBase.identificadorRecurso;
        movimento.movimentoFinanceiro.valor = detalhamentosTitulo[indexDetalhamento].valor;
        movimento.movimentoFinanceiro.anexos = [];

        if (detalhamentosTitulo[indexDetalhamento].tipo === ETipoDetalhamentoTituloFinanceiro.Informativo) {
          movimento.referente = EMovimentoFinanceiroBaixaReferente.BaixaTitulo;
          movimento.movimentoFinanceiro.tipoMovimento = baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Receber ? ETipoMovimentoFinanceiro.Recebimento : ETipoMovimentoFinanceiro.Pagamento;
        } else if (detalhamentosTitulo[indexDetalhamento].tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento) {
          movimento.referente = EMovimentoFinanceiroBaixaReferente.Abatimento;
          movimento.movimentoFinanceiro.tipoMovimento = baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Receber ? ETipoMovimentoFinanceiro.Pagamento : ETipoMovimentoFinanceiro.Recebimento;
        }

        movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
        if (adicionarTituloBaixado && (indexDetalhamento === indexMaiorDetalhamentoInformativo)) {
          movimento.titulosBaixados.push(obterTituloBaixa(tituloFinanceiro, renegociado, totalAbatimentosTitulo));
        }
        movimentosFinanceiros.push(movimento);
      }
    } else {
      numeroMovimentoControle += 1;
      const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
      movimento.referente = referente;
      movimento.codigoTituloFinanceiroOrigem = tituloFinanceiro.codigoTituloFinanceiro;
      movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
      movimento.movimentoFinanceiro.codigoConta = codigoConta;
      movimento.movimentoFinanceiro.codigoPlanoContaCategoria = codigoPlanoContaCategoria;
      movimento.movimentoFinanceiro.codigoEmpresa = tituloFinanceiro.codigoEmpresa;
      movimento.movimentoFinanceiro.codigoPessoa = tituloFinanceiro.codigoPessoa;
      movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = tituloFinanceiro.codigoTipoDocumentoFinanceiro;
      movimento.movimentoFinanceiro.conciliado = false;
      movimento.movimentoFinanceiro.influenciaSaldo = influenciaSaldo;
      movimento.movimentoFinanceiro.dataMovimento = baixaTitulosBase.dataBaixa;
      movimento.movimentoFinanceiro.descricao = descricaoMovimento;
      movimento.movimentoFinanceiro.identificadorMovimento = `${tituloFinanceiro.numeroTitulo}-${complementoIdentificador}-M${numeroMovimentoControle}`;
      movimento.movimentoFinanceiro.marcadores = [];
      movimento.movimentoFinanceiro.recursoOrigem = baixaTitulosBase.identificadorRecurso;
      movimento.movimentoFinanceiro.tipoMovimento = tipoMovimento;
      movimento.movimentoFinanceiro.valor = valor;
      movimento.movimentoFinanceiro.anexos = [];
      movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
      if (adicionarTituloBaixado) {
        movimento.titulosBaixados.push(obterTituloBaixa(tituloFinanceiro, renegociado));
      }

      movimentosFinanceiros.push(movimento);
    }

    return movimentosFinanceiros;
  }

  function executaBaixaLancamentosConta(lancamentosConta: IDTOLancamentoBaixaConta[], influenciaSaldo: boolean, descricaoPrincipal: string, renegociado = false) {
    let tipoMovimentoDesconto: ETipoMovimentoFinanceiro;
    if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Receber) {
      tipoMovimentoOperacao = ETipoMovimentoFinanceiro.Recebimento;
      tipoMovimentoDesconto = ETipoMovimentoFinanceiro.Pagamento;
    } else if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Pagar) {
      tipoMovimentoOperacao = ETipoMovimentoFinanceiro.Pagamento;
      tipoMovimentoDesconto = ETipoMovimentoFinanceiro.Recebimento;
    }
    baixaTitulosBase.movimentosInvalidos = false;

    lancamentosConta.forEach((detalheLancamentoConta) => {
      if (baixaTitulosBase.movimentosInvalidos) {
        movimentosFinanceiros = [];
        return;
      }
      let valorDisponivelBaixa = UtilitarioFinanceiro.valorDecimal2Casas(detalheLancamentoConta.valor);

      for (let index = 0; index < titulosParaBaixar.length; index += 1) {
        let criarMovimento = true;
        if (descricaoPrincipal === 'BAIXA UTILIZANDO CRÉDITO' && baixaTitulosBase.utilizarCreditosApenasMesmaPessoa) {
          if (detalheLancamentoConta.codigoPessoa > 0 && detalheLancamentoConta.codigoPessoa !== titulosParaBaixar[index].codigoPessoa) { criarMovimento = false; }
        }
        if (valorDisponivelBaixa > 0) {
          if (criarMovimento && titulosParaBaixar[index].saldo > 0) {
            const valorPendente = UtilitarioFinanceiro.valorDecimal2Casas(((titulosParaBaixar[index].saldo + titulosParaBaixar[index].valorJurosMulta + titulosParaBaixar[index].valorAcrescimo) - titulosParaBaixar[index].valorDesconto));

            if (valorPendente <= valorDisponivelBaixa) {
              titulosParaBaixar[index].valorPago = titulosParaBaixar[index].saldo;
              valorDisponivelBaixa -= UtilitarioFinanceiro.valorDecimal2Casas((titulosParaBaixar[index].saldo + titulosParaBaixar[index].valorJurosMulta + titulosParaBaixar[index].valorAcrescimo));
              movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.BaixaTitulo, descricaoPrincipal, 'BAIXA', influenciaSaldo, titulosParaBaixar[index].saldo, titulosParaBaixar[index].codigoPlanoContaCategoria, true, titulosParaBaixar[index], renegociado));
              if (baixaTitulosBase.movimentosInvalidos) {
                movimentosFinanceiros = [];
                break;
              }
              if (titulosParaBaixar[index].valorJurosMulta > 0) {
                movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.JurosMulta, 'JUROS/MULTAS', 'JUROS-MULTA', influenciaSaldo, titulosParaBaixar[index].valorJurosMulta, baixaTitulosBase.categoriaPadraoJurosMulta, false, titulosParaBaixar[index], renegociado));
                titulosParaBaixar[index].valorJurosMulta = 0;
              }
              if (titulosParaBaixar[index].valorAcrescimo > 0) {
                movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.Acrescimo, 'ACRÉSCIMO', 'ACRESCIMO', influenciaSaldo, titulosParaBaixar[index].valorAcrescimo, titulosParaBaixar[index].codigoPlanoContaCategoria, false, titulosParaBaixar[index], renegociado));
                titulosParaBaixar[index].valorAcrescimo = 0;
              }
              if (titulosParaBaixar[index].valorDesconto > 0) {
                movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoDesconto, EMovimentoFinanceiroBaixaReferente.Desconto, 'DESCONTO', 'DESCONTO', influenciaSaldo, titulosParaBaixar[index].valorDesconto, baixaTitulosBase.categoriaPadraoDesconto, false, titulosParaBaixar[index], renegociado));
                valorDisponivelBaixa += titulosParaBaixar[index].valorDesconto;
                titulosParaBaixar[index].valorDesconto = 0;
              }
              titulosParaBaixar[index].saldo = 0;
            } else {
              if (valorDisponivelBaixa > 0) {
                if (titulosParaBaixar[index].valorJurosMulta > 0 && titulosParaBaixar[index].valorJurosMulta < valorDisponivelBaixa) {
                  movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.JurosMulta, 'JUROS/MULTAS', 'JUROS-MULTA', influenciaSaldo, titulosParaBaixar[index].valorJurosMulta, baixaTitulosBase.categoriaPadraoJurosMulta, false, titulosParaBaixar[index], renegociado));
                  valorDisponivelBaixa -= UtilitarioFinanceiro.valorDecimal2Casas(titulosParaBaixar[index].valorJurosMulta);
                  titulosParaBaixar[index].valorJurosMulta = 0;
                } else if (titulosParaBaixar[index].valorJurosMulta > 0 && titulosParaBaixar[index].valorJurosMulta > valorDisponivelBaixa) {
                  movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.JurosMulta, 'JUROS/MULTAS', 'JUROS-MULTA', influenciaSaldo, valorDisponivelBaixa, baixaTitulosBase.categoriaPadraoJurosMulta, false, titulosParaBaixar[index], renegociado));
                  valorDisponivelBaixa = 0;
                  titulosParaBaixar[index].valorJurosMulta -= valorDisponivelBaixa;
                }
              }

              if (valorDisponivelBaixa > 0) {
                if (titulosParaBaixar[index].valorAcrescimo > 0 && titulosParaBaixar[index].valorAcrescimo < valorDisponivelBaixa) {
                  movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.Acrescimo, 'ACRÉSCIMO', 'ACRESCIMO', influenciaSaldo, titulosParaBaixar[index].valorAcrescimo, titulosParaBaixar[index].codigoPlanoContaCategoria, false, titulosParaBaixar[index], renegociado));
                  valorDisponivelBaixa -= UtilitarioFinanceiro.valorDecimal2Casas(titulosParaBaixar[index].valorAcrescimo);
                  titulosParaBaixar[index].valorAcrescimo = 0;
                } else if (titulosParaBaixar[index].valorAcrescimo > 0 && titulosParaBaixar[index].valorAcrescimo > valorDisponivelBaixa) {
                  movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.Acrescimo, 'ACRÉSCIMO', 'ACRESCIMO', influenciaSaldo, valorDisponivelBaixa, titulosParaBaixar[index].codigoPlanoContaCategoria, false, titulosParaBaixar[index], renegociado));
                  valorDisponivelBaixa = 0;
                  titulosParaBaixar[index].valorAcrescimo -= UtilitarioFinanceiro.valorDecimal2Casas(valorDisponivelBaixa);
                }
              }

              if (titulosParaBaixar[index].valorDesconto > 0) {
                movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoDesconto, EMovimentoFinanceiroBaixaReferente.Desconto, 'DESCONTO', 'DESCONTO', influenciaSaldo, titulosParaBaixar[index].valorDesconto, baixaTitulosBase.categoriaPadraoDesconto, false, titulosParaBaixar[index], renegociado));
                valorDisponivelBaixa += titulosParaBaixar[index].valorDesconto;
                titulosParaBaixar[index].valorDesconto -= titulosParaBaixar[index].valorDesconto;
              }

              if (valorDisponivelBaixa > 0 && titulosParaBaixar[index].saldo > 0) {
                const tituloFinanceiro = UtilitarioGeral.instanciaObjetoLocal(titulosParaBaixar[index]);
                tituloFinanceiro.valorJurosMulta = titulosOriginais[index].valorJurosMulta;
                tituloFinanceiro.valorAcrescimo = titulosOriginais[index].valorAcrescimo;
                tituloFinanceiro.valorDesconto = titulosOriginais[index].valorDesconto;

                if (titulosParaBaixar[index].saldo < valorDisponivelBaixa) {
                  titulosParaBaixar[index].valorPago = titulosParaBaixar[index].saldo;
                  tituloFinanceiro.valorPago = titulosParaBaixar[index].saldo;
                  movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.BaixaTitulo, descricaoPrincipal, 'BAIXA', influenciaSaldo, titulosParaBaixar[index].saldo, titulosParaBaixar[index].codigoPlanoContaCategoria, true, tituloFinanceiro, renegociado));
                  if (baixaTitulosBase.movimentosInvalidos) {
                    movimentosFinanceiros = [];
                    break;
                  }
                  valorDisponivelBaixa -= UtilitarioFinanceiro.valorDecimal2Casas(titulosParaBaixar[index].saldo);
                  titulosParaBaixar[index].saldo = 0;
                } else if (titulosParaBaixar[index].saldo > valorDisponivelBaixa) {
                  titulosParaBaixar[index].valorPago = valorDisponivelBaixa;
                  tituloFinanceiro.valorPago = valorDisponivelBaixa;
                  movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, tipoMovimentoOperacao, EMovimentoFinanceiroBaixaReferente.BaixaTitulo, descricaoPrincipal, 'BAIXA', influenciaSaldo, valorDisponivelBaixa, titulosParaBaixar[index].codigoPlanoContaCategoria, true, tituloFinanceiro, renegociado));
                  if (baixaTitulosBase.movimentosInvalidos) {
                    movimentosFinanceiros = [];
                    break;
                  }
                  titulosParaBaixar[index].saldo -= UtilitarioFinanceiro.valorDecimal2Casas(valorDisponivelBaixa);
                  valorDisponivelBaixa = 0;
                }
              }
            }
          }
        } else {
          break;
        }
      }
    });
  }

  function executaBaixaCreditosUtilizados(influenciaSaldo: boolean, descricaoPrincipal: string) {
    let tipoMovimentoOperacaoCredito: ETipoMovimentoFinanceiro = ETipoMovimentoFinanceiro.Pagamento;

    if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Receber) {
      tipoMovimentoOperacaoCredito = ETipoMovimentoFinanceiro.Pagamento;
    } else if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Pagar) {
      tipoMovimentoOperacaoCredito = ETipoMovimentoFinanceiro.Recebimento;
    }

    for (let index = 0; index < creditosParaBaixar.length; index += 1) {
      if (creditosParaBaixar[index].valorUtilizado > 0) {
        creditosParaBaixar[index].valorPago = creditosParaBaixar[index].valorUtilizado;
        movimentosFinanceiros.push(...criarMovimentoFinanceiro(creditosParaBaixar[index].codigoConta, tipoMovimentoOperacaoCredito, EMovimentoFinanceiroBaixaReferente.BaixaTitulo, descricaoPrincipal, 'BAIXA', influenciaSaldo, creditosParaBaixar[index].valorUtilizado, creditosParaBaixar[index].codigoPlanoContaCategoria, true, creditosParaBaixar[index], false));
      }
    }
  }

  async function prepararPreviaBaixa() {
    movimentosFinanceiros = [];
    baixaTitulosBase.movimentosFinanceiros = [];
    baixaTitulosBase.lancamentosContaCreditos = [];
    baixaTitulosBase.lancamentosContaTitulosLancados = [];
    baixaTitulosBase.lancamentosContaTitulosRepassados = [];

    const dados:IDTOTituloFinanceiroObterDetalhamentoVariosTitulos = {} as IDTOTituloFinanceiroObterDetalhamentoVariosTitulos;
    dados.empresas = baixaTitulosBase.titulosBaixa.map((c) => c.codigoEmpresa);
    dados.tipoTituloFinanceiro = baixaTitulosBase.tipoTituloBaixa;
    dados.codigosTituloFinanceiros = baixaTitulosBase.titulosBaixa.map((c) => c.codigoTituloFinanceiro);

    if (baixaTitulosBase.buscarDadosAtualizadosDetalhamentos) {
      if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Receber) {
        baixaTitulosBase.detalhesPagamentos = await servicoTituloFinanceiroReceber.obterDetalhamentosVariosTitulos(dados);
      } else if (baixaTitulosBase.tipoTituloBaixa === ETipoTituloFinanceiro.Pagar) {
        baixaTitulosBase.detalhesPagamentos = await servicoTituloFinanceiroPagar.obterDetalhamentosVariosTitulos(dados);
      }
      baixaTitulosBase.buscarDadosAtualizadosDetalhamentos = false;
    }
    numeroMovimentoControle = 0;
    if (baixaTitulosBase.totalJurosMulta > 0 && !UtilitarioGeral.validaCodigo(baixaTitulosBase.categoriaPadraoJurosMulta)) {
      apresentarMensagemAlerta('Para visualizar a prévia da baixa é necessário informar a Categoria Padrão de Juros/Multa!');
      baixaTitulosBase.passoAtivo = '1';
      return;
    }

    if (baixaTitulosBase.totalDesconto > 0 && !UtilitarioGeral.validaCodigo(baixaTitulosBase.categoriaPadraoDesconto)) {
      apresentarMensagemAlerta('Para visualizar a prévia da baixa é necessário informar a Categoria Padrão de Descontos!');
      baixaTitulosBase.passoAtivo = '1';
      return;
    }

    if (baixaTitulosBase.totalTitulosRepassados > 0 && !baixaTitulosBase.baixaComDetalhamentos) {
      const indexPessoaInvalida = baixaTitulosBase.titulosRepassados.findIndex((c) => c.codigoPessoa === 0);
      const indexMotivoInvalido = baixaTitulosBase.titulosRepassados.findIndex((c) => c.motivoRepasse === '');
      if (indexPessoaInvalida >= 0 || indexMotivoInvalido >= 0) {
        apresentarMensagemAlerta('Dados do Repasse inválidos, confirme por favor a Pessoa e o Motivo!');
        baixaTitulosBase.passoAtivo = '6';
        return;
      }
    }
    titulosOriginais = UtilitarioGeral.instanciaObjetoLocal(baixaTitulosBase.titulosBaixa);
    titulosParaBaixar = UtilitarioGeral.instanciaObjetoLocal(baixaTitulosBase.titulosBaixa);
    creditosParaBaixar = UtilitarioGeral.instanciaObjetoLocal(baixaTitulosBase.creditosUtilizados);

    if (baixaTitulosBase.totalCreditosUtilizados > 0 || baixaTitulosBase.totalTitulosLancados > 0 || baixaTitulosBase.totalLancamentosContaNormais > 0 || baixaTitulosBase.totalTitulosRepassados > 0) {
      if (UtilitarioGeral.validaLista(baixaTitulosBase.creditosUtilizados) && !baixaTitulosBase.baixaComDetalhamentos) {
        baixaTitulosBase.creditosUtilizados.forEach((credito) => {
          if (baixaTitulosBase.utilizarCreditosApenasMesmaPessoa) {
            const index = baixaTitulosBase.lancamentosContaCreditos.findIndex((c) => c.codigoConta === credito.codigoConta && c.codigoPessoa === credito.codigoPessoa);
            if (index >= 0) {
              baixaTitulosBase.lancamentosContaCreditos[index].valor += credito.valorUtilizado;
            } else {
              const lancamentoContaCredito: IDTOLancamentoBaixaConta = {
                codigoConta: credito.codigoConta, descricaoConta: credito.descricaoConta, dataBaixa: baixaTitulosBase.dataBaixa, codigoPessoa: credito.codigoPessoa, valor: credito.valorUtilizado,
              };
              baixaTitulosBase.lancamentosContaCreditos.push(lancamentoContaCredito);
            }
          } else {
            const index = baixaTitulosBase.lancamentosContaCreditos.findIndex((c) => c.codigoConta === credito.codigoConta);
            if (index >= 0) {
              baixaTitulosBase.lancamentosContaCreditos[index].valor += credito.valorUtilizado;
            } else {
              const lancamentoContaCredito: IDTOLancamentoBaixaConta = {
                codigoConta: credito.codigoConta, descricaoConta: credito.descricaoConta, dataBaixa: baixaTitulosBase.dataBaixa, codigoPessoa: 0, valor: credito.valorUtilizado,
              };
              baixaTitulosBase.lancamentosContaCreditos.push(lancamentoContaCredito);
            }
          }
        });

        executaBaixaLancamentosConta(baixaTitulosBase.lancamentosContaCreditos, false, 'BAIXA UTILIZANDO CRÉDITO');
        executaBaixaCreditosUtilizados(false, 'BAIXA DO CRÉDITO');
      }

      if (UtilitarioGeral.validaLista(baixaTitulosBase.titulosLancados) && !baixaTitulosBase.baixaComDetalhamentos) {
        baixaTitulosBase.titulosLancados.forEach((tituloFinanceiro) => {
          const index = baixaTitulosBase.lancamentosContaTitulosLancados.findIndex((c) => c.codigoConta === tituloFinanceiro.codigoConta);
          if (index >= 0) {
            baixaTitulosBase.lancamentosContaTitulosLancados[index].valor += tituloFinanceiro.saldo;
          } else {
            const lancamentoContaNovosTitulos: IDTOLancamentoBaixaConta = {
              codigoConta: tituloFinanceiro.codigoConta, descricaoConta: tituloFinanceiro.descricaoConta, dataBaixa: baixaTitulosBase.dataBaixa, codigoPessoa: 0, valor: tituloFinanceiro.saldo,
            };
            baixaTitulosBase.lancamentosContaTitulosLancados.push(lancamentoContaNovosTitulos);
          }
        });

        executaBaixaLancamentosConta(baixaTitulosBase.lancamentosContaTitulosLancados, false, 'BAIXA NOVO TÍTULO/RENEGOCIADO', true);
      }

      if (UtilitarioGeral.validaLista(baixaTitulosBase.lancamentosContaNormais)) {
        executaBaixaLancamentosConta(baixaTitulosBase.lancamentosContaNormais, true, 'BAIXA TÍTULO');
      }

      if (UtilitarioGeral.validaLista(baixaTitulosBase.titulosRepassados) && !baixaTitulosBase.baixaComDetalhamentos) {
        baixaTitulosBase.titulosRepassados.forEach((tituloFinanceiro) => {
          const index = baixaTitulosBase.lancamentosContaTitulosRepassados.findIndex((c) => c.codigoConta === tituloFinanceiro.codigoConta);
          if (index >= 0) {
            baixaTitulosBase.lancamentosContaTitulosRepassados[index].valor += tituloFinanceiro.saldo;
          } else {
            const lancamentoContaRepassados: IDTOLancamentoBaixaConta = {
              codigoConta: tituloFinanceiro.codigoConta, descricaoConta: tituloFinanceiro.descricaoConta, dataBaixa: baixaTitulosBase.dataBaixa, codigoPessoa: 0, valor: tituloFinanceiro.saldo,
            };
            baixaTitulosBase.lancamentosContaTitulosRepassados.push(lancamentoContaRepassados);
          }
        });
        executaBaixaLancamentosConta(baixaTitulosBase.lancamentosContaTitulosRepassados, false, 'BAIXA TÍTULO REPASSADO');
      }
    }

    baixaTitulosBase.movimentosFinanceiros = movimentosFinanceiros;
    calcularTotalizadoresMovimentos();
  }
  return {
    baixaTitulosBase,
    atualizarValorRestante,
    limparTelaBase,
    zerarValores,
    calcularAcrescimosDescontosGerais,
    calcularPercentuais,
    verificacaoCalculo,
    verificacaoAlteracaoData,
    verificacaoAlertaBaixa,
    calcularTotalizadoresMovimentos,
    calcularValores,
    obterDadosFinanceirosClientes,
    popularTitulosBaixa,
    obterDescricaoConta,
    obterDescricaoTipoMovimento,
    obterDescricaoInfluenciaSaldo,
    obterTitulosLancados,
    obterObjetoBaixa,
    valorJurosMultaDigitado,
    valorAcrescimoDigitado,
    valorDescontoDigitado,
    prepararPreviaBaixa,
  };
}
